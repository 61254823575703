import React from 'react';
import { Avatar } from '@mui/material';

const getInitials = (user = {}) => {
  if (!user.name) {
    return '?';
  }

  const names = user.name.split(' ');

  return names
    .slice(0, 2)
    .map((n) => n[0])
    .join('')
    .toUpperCase();
};

export default function UserAvatar({ children, user = {}, ...props }) {
  return (
    <Avatar alt={user.name} src={user.image} {...props}>
      {children || getInitials(user)}
    </Avatar>
  );
}
